import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  inject,
  Input,
  Output,
} from '@angular/core';

@Directive({
  selector: '[appDropZone]',
  standalone: true,
})
export class DropZoneDirective {
  @Input()
  appDropZone = false;

  @Output()
  DropCaused = new EventEmitter<FileList>();

  host = inject(ElementRef);

  @HostListener('dragover', ['$event'])
  onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();

    if (!event.dataTransfer) {
      return;
    }

    if (!this.appDropZone) {
      event.dataTransfer!.effectAllowed = 'none';
      event.dataTransfer!.dropEffect = 'none';
    } else {
      event.dataTransfer!.effectAllowed = 'copy';
      event.dataTransfer!.dropEffect = 'copy';
    }
  }

  @HostListener('drop', ['$event'])
  onDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    if (!this.appDropZone) {
      return;
    }

    if (!event.dataTransfer) {
      return;
    }

    if (event.dataTransfer.files.length === 0) {
      return;
    }

    this.DropCaused.emit(event.dataTransfer.files);
  }
}
